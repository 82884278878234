<template lang="pug">
    form.form-recuperar-senha(@submit.prevent="handleSubmit()")
        h1.text-title.ta-center.text-primary Recuperar senha

        p.text-help Por favor, digite seu e-mail para enviarmos um link de redefinição de senha.

        .p-inputgroup.my-1(:class="{ 'form-group--error': form.submitted && $v.form.model.email.$error }")
            span.p-inputgroup-addon <i class="pi pi-inbox"></i>
            InputText(placeholder="E-mail" v-model='$v.form.model.email.$model')
        .feedback--errors(v-if='form.submitted && $v.form.model.email.$error')
            .form-message--error(v-if="!$v.form.model.email.required") <b>E-mail</b> é obrigatório.
            .form-message--error(v-if="!$v.form.model.email.email") <b>E-mail</b> inválido.
        
        .p-inputgroup.my-1
            ProgressBar.progressbar--button(v-if='form.waiting' mode='indeterminate')
            .w-100(v-else)
                Button.w-100(label="Enviar link" type='submit')
                .ta-right.mt-1
                    a.text-esqsuasenha.text-secondary(@click='$router.push("/usuario/auth/login")') Voltar para login
</template>

<style lang="scss">
    .form-recuperar-senha {
        .text-help {
            font-size: 12px;
            margin-bottom: 20px;
        }
    }
</style>

<script>
    import InputText from 'primevue/inputtext'
    import Button from 'primevue/button'
    import ProgressBar from 'primevue/progressbar'

    import { required, email } from 'vuelidate/lib/validators'
    import { Usuario } from '../../middleware'
    export default {
        components: { InputText, Button, ProgressBar },
        data () {
            return {
                form: {
                    model: {
                        email: ''
                    },
                    submitted: false,
                    waiting: false
                }
            }
        },
        validations: {
            form: {
                model: {
                    email: { required, email }
                }
            }
        },
        methods: {
            handleSubmit () {
                this.form.submitted = true

                this.$v.$touch()
                if (this.$v.$invalid) return 0

                this.form.waiting = true

                Usuario.forgotPassword(this.form.model.email).then(response => {
                    this.form.waiting = false
                    if (response.status == 200) {
                        this.$toast.success(response.data.info, { duration: 4000 })
                        this.$router.push("/usuario/auth/login")
                    } else if (response.status == 404) {
                        this.$toast.error('E-mail não cadastrado', { duration: 4000 })
                    }
                })
            }
        }
    }
</script>